import {maxLength, minValue, numeric, decimal, required} from "vuelidate/lib/validators";

export const schema = {
  order: {
    orderNumber: {
      required,
      maxLength: maxLength(64),
    },
    price: {
      required,
      decimal,
      // minValue: minValue(0),
    },
    total: {
      required,
      decimal,
      // minValue: minValue(0),
    },
    discount: {
      required,
      decimal,
      // minValue: minValue(0),
    },
    delivery: {
      required,
      decimal,
      // minValue: minValue(0),
    },
    forms: {
      $each: {
        town: {
          maxLength: maxLength(255),
        },
        address: {
          required,
          maxLength: maxLength(255),
        },
        name: {
          maxLength: maxLength(255),
        },
        phone: {
          required,
          maxLength: maxLength(255),
        },
        reason: {
          maxLength: maxLength(255),
        },
      }
    },
    deliveryDates: {
      $each: {
        date: {
          required,
        },
        note: {
          maxLength: maxLength(255),
        }
      }
    },
    positions: {
      $each: {
        id: {
          numeric,
        },
        title: {
          required,
          maxLength: maxLength(255),
        },
        priceTagTitle: {
          maxLength: maxLength(255),
        },
        article: {
          maxLength: maxLength(255),
        },
        quantity: {
          required,
          numeric,
          minValue: minValue(0),
        },
        discount: {
          required,
          decimal,
          // minValue: minValue(0),
        },
        price: {
          required,
          decimal,
          // minValue: minValue(0),
        },
        priceFixed: {
        },
        removed: {
        },
        product: {
        },
        priceTag: {
        }
      }
    },
    statuses: {
      $each: {}
    },
    managers: {
      $each: {}
    },
    drivers: {
      $each: {}
    },
    paymentMethods: {
      $each: {}
    },
  }
};
