<template>
  <div class="app flex-row align-items-center">
    <div class="container vue-form-generator">
      <b-card v-if="order">
        <div slot="header">
          <strong>{{ $t('order #') }} {{ order.orderNumber }}</strong>
        </div>
        <b-alert show variant="warning" v-if="order && order.users.length>0 && order.users[0].alerts.length>0">
          <h2>{{ $t('Attention') }}</h2>
          <span>{{ $t('clientInBlackList') }} <router-link target='_blank' :to="{name: 'UpdateUserAlert', params: {id: order.users[0].alerts[0].id}}">
            {{ $t('followLink') }}
          </router-link> {{ $t('forDetails') }}</span>.
        </b-alert>
        <h5>{{ $t('orderData') }}</h5>
        <b-card>
          <b-row>
            <b-col sm="12" md="3" lg="2" :class="{ error: $v.order.orderNumber.$invalid }">
              <label>{{ $t('orderNumber') }}</label>
              <b-form-input v-model="$v.order.orderNumber.$model" max="64" required/>
              <div v-if="$v.order.orderNumber.$invalid && $v.order.orderNumber.$dirty">
                <div class="errors" v-if="!$v.order.orderNumber.required">{{ $t('orderNumberRequired') }}</div>
                <div class="errors" v-if="!$v.order.orderNumber.maxLength">{{ $t('orderNumberMaxLength') }}</div>
              </div>
            </b-col>
            <b-col sm="12" md="4" lg="2" :class="{ error: $v.order.deliveryDates.$each[0].date.$invalid }">
              <label>{{ $t('date') }}</label>
              <date-picker v-model="$v.order.deliveryDates.$each[0].date.$model" :config="dateOptions"/>
              <div v-if="$v.order.deliveryDates.$each[0].date.$invalid && $v.order.deliveryDates.$each[0].date.$dirty">
                <div class="errors" v-if="!$v.order.deliveryDates.$each[0].date.required">{{ $t('orderDeliveryDateRequired') }}</div>
              </div>
            </b-col>
            <b-col sm="12" md="5" lg="2" :class="{ error: $v.order.deliveryDates.$each[0].note.$invalid }">
              <label>{{ $t('timeDelivery') }}</label>
              <b-form-input v-model="$v.order.deliveryDates.$each[0].note.$model" max="255" />
              <div v-if="$v.order.deliveryDates.$each[0].note.$invalid && $v.order.deliveryDates.$each[0].note.$dirty">
                <div class="errors" v-if="!$v.order.deliveryDates.$each[0].note.maxLength">{{ $t('timeDeliveryMaxLength') }}</div>
              </div>
            </b-col>
            <b-col sm="12" md="4" lg="3" :class="{ error: $v.order.forms.$each[0].name.$invalid }">
              <label>{{ $t('customer') }}</label>
              <b-form-input v-model="$v.order.forms.$each[0].name.$model" max="255" />
              <div v-if="$v.order.forms.$each[0].name.$invalid && $v.order.forms.$each[0].name.$dirty">
                <div class="errors" v-if="!$v.order.forms.$each[0].name.maxLength">{{ $t('orderNameMaxLength') }}</div>
              </div>
            </b-col>
            <b-col sm="12" md="4" lg="3" :class="{ error: $v.order.forms.$each[0].phone.$invalid }">
              <label>{{ $t('phone') }}</label>
              <b-form-input v-model="$v.order.forms.$each[0].phone.$model" max="255" required />
              <div v-if="$v.order.forms.$each[0].phone.$invalid && $v.order.forms.$each[0].phone.$dirty">
                <div class="errors" v-if="!$v.order.forms.$each[0].phone.required">{{ $t('orderPhoneRequired') }}</div>
                <div class="errors" v-if="!$v.order.forms.$each[0].phone.maxLength">{{ $t('orderPhoneMaxLength') }}</div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="9" lg="7">
              <label>{{ $t('address') }}</label>
              <b-row>
                <b-col md="5" lg="4" :class="{ error: $v.order.forms.$each[0].town.$invalid }">
                  <b-form-input v-model="$v.order.forms.$each[0].town.$model" max="255"/>
                  <div v-if="$v.order.forms.$each[0].town.$invalid && $v.order.forms.$each[0].town.$dirty">
                    <div class="errors" v-if="!$v.order.forms.$each[0].town.maxLength">{{ $t('orderTownMaxLength') }}</div>
                  </div>
                </b-col>
                <b-col md="7" lg="8" :class="{ error: $v.order.forms.$each[0].address.$invalid }">
                  <b-form-input v-model="$v.order.forms.$each[0].address.$model" max="255" required/>
                  <div v-if="$v.order.forms.$each[0].address.$invalid && $v.order.forms.$each[0].address.$dirty">
                    <div class="errors" v-if="!$v.order.forms.$each[0].address.required">{{ $t('orderAddressRequired') }}</div>
                    <div class="errors" v-if="!$v.order.forms.$each[0].address.maxLength">{{ $t('orderAddressMaxLength') }}</div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col sm="12" md="3" lg="3" :class="{ error: $v.order.forms.$each[0].reason.$invalid }">
              <label>{{ $t('note') }}</label>
              <b-form-input v-model="$v.order.forms.$each[0].reason.$model" max="255" />
              <div v-if="$v.order.forms.$each[0].reason.$invalid && $v.order.forms.$each[0].reason.$dirty">
                <div class="errors" v-if="!$v.order.forms.$each[0].reason.maxLength">{{ $t('orderReasonMaxLength') }}</div>
              </div>
            </b-col>
            <b-col sm="12" md="4" lg="2">
              <label>{{ $t('role') }}</label>
              <b-form-input :value="getRole(order)" disabled/>
            </b-col>
          </b-row>
        </b-card>
        <h5>{{ $t('orderPositions') }}</h5>
        <b-card>
          <div>
            <b-row>
              <b-col sm="12" md="4" lg="2">
                <label>{{ $t('orderPositionArticle') }}</label>
              </b-col>
              <b-col sm="12" md="4" lg="4">
                <label>{{ $t('orderPositionTitle') }}</label>
              </b-col>
              <b-col sm="12" md="4" lg="2">
                <label>{{ $t('orderPositionPriceTagTitle') }}</label>
              </b-col>
              <b-col sm="12" md="4" lg="1">
                <label>{{ $t('orderPositionQuantity') }}</label>
              </b-col>
              <b-col sm="12" md="4" lg="3">
                <label>{{ $t('orderPositionPrice') }}</label>
              </b-col>
            </b-row>
          </div>
          <div v-for="(position, i) in $v.order.positions.$each.$iter" :key="i" style="margin-bottom: 7px;">
            <div v-if="!position.removed.$model">
              <b-row>
                <b-col sm="12" md="4" lg="2" :class="{ error: position.article.$dirty && position.article.$invalid }">
                  <b-form-input v-model="position.article.$model" max="255" />
                </b-col>
                <b-col sm="12" md="8" lg="6">
                  <order-position-autocomplete
                    :position="position"
                    :role="order.role"
                    :index="i"
                  />
                </b-col>
                <b-col sm="12" md="4" lg="1" :class="{ error: position.quantity.$dirty && position.quantity.$invalid }">
                  <b-form-input v-model="position.quantity.$model" type="number" :number="true" min="0" required/>
                </b-col>
                <b-col sm="12" md="4" lg="3">
                  <b-row>
                    <b-col sm="12" md="5" :class="{ error: position.price.$dirty && position.price.$invalid }">
                      <b-form-input 
                        v-model="position.price.$model"
                        @change="position.priceFixed.$model = true"
                        :class="{'fixed-price': position.priceFixed.$model}"
                        type="number"
                        :number="true"
                        min="0"
                        required
                      />
                    </b-col>
                    <b-col sm="12" md="5">
                      <b-form-input disabled :value="getRoundValue(position.quantity.$model * position.price.$model)"/>
                    </b-col>
                    <b-col sm="12" md="2">
                      <a v-if="order.positions.length > 1" href="#" class="fa fa-remove"
                        @click.prevent="removePosition(position)"/>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" md="4" lg="8" :class="{error: position.priceTagTitle.$invalid || position.title.$invalid ||position.article.$invalid}">
                  <div v-if="position.article.$invalid && position.article.$dirty">
                    <div class="errors" v-if="!position.article.maxLength">{{ $t('orderPositionArticleMaxLength') }}</div>
                  </div>
                  <div v-if="position.title.$invalid && position.title.$dirty">
                    <div class="errors" v-if="!position.title.required">{{ $t('orderPositionTitleRequired') }}</div>
                    <div class="errors" v-if="!position.title.maxLength">{{ $t('orderPositionTitleMaxLength') }}</div>
                  </div>
                  <div v-if="position.priceTagTitle.$invalid && position.priceTagTitle.$dirty">
                    <div class="errors" v-if="!position.priceTagTitle.maxLength">{{ $t('orderPositionPriceTagTitleMaxLength') }}</div>
                  </div>
                </b-col>
                <b-col sm="12" md="4" lg="4" :class="{error:position.quantity.$invalid || position.price.$invalid}">
                  <div v-if="position.quantity.$invalid && position.quantity.$dirty">
                    <div class="errors" v-if="!position.quantity.required">{{ $t('orderPositionQuantityRequired') }}</div>
                    <div class="errors" v-if="position.quantity.required && !position.quantity.numeric">{{ $t('orderPositionQuantityNumeric') }}</div>
                    <div class="errors" v-if="position.quantity.numeric && !position.quantity.minValue">{{ $t('orderPositionQuantityMinValue') }}</div>
                  </div>
                  <div v-if="position.price.$invalid && position.price.$dirty">
                    <div class="errors" v-if="!position.price.required">{{ $t('orderPositionPriceRequired') }}</div>
                    <!-- <div class="errors" v-if="position.price.required && !position.price.numeric">{{ $t('orderPositionPriceNumeric') }}</div> -->
                    <!-- <div class="errors" v-if="position.price.numeric && !position.price.minValue">{{ $t('orderPositionPriceMinValue') }}</div> -->
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
          <b-row>
            <b-col sm="12">
              <a href="#" v-if="showAddPosition" @click.prevent="addPosition()">
                {{ $t('addOrderPosition') }}
              </a>
            </b-col>
          </b-row>
        </b-card>
        <h5>{{ $t('orderTotals') }}</h5>
        <b-card>
          <b-row>
            <b-col sm="12" md="6" lg="3" :class="{ error: $v.order.total.$invalid }">
              <label>{{ $t('orderPrice') }}</label>
              <b-form-input 
                v-model="$v.order.total.$model" 
                @change="recalculateOrderSumamry"
                type="number" 
                :number="true" 
                required 
                min="0"
              />
              <div v-if="$v.order.total.$invalid && $v.order.total.$dirty">
                <div class="errors" v-if="!$v.order.total.required">{{ $t('orderTotalRequired') }}</div>
                <!-- <div class="errors" v-if="$v.order.total.required && !$v.order.total.numeric">{{ $t('orderTotalNumeric') }}</div> -->
                <!-- <div class="errors" v-if="$v.order.total.numeric && !$v.order.total.minValue">{{ $t('orderTotalMinValue') }}</div> -->
              </div>
            </b-col>
            <b-col sm="12" md="6" lg="3" :class="{ error: $v.order.discount.$invalid }">
              <label>{{ $t('orderDiscount') }}</label>
              <b-form-input 
                v-model="$v.order.discount.$model"
                @change="recalculateOrderSumamry"
                type="number" 
                :number="true" 
                required 
                min="0" 
              />
              <div v-if="$v.order.discount.$invalid && $v.order.discount.$dirty">
                <div class="errors" v-if="!$v.order.discount.required">{{ $t('orderDiscountRequired') }}</div>
                <div class="errors" v-if="$v.order.discount.required && !$v.order.discount.numeric">{{ $t('orderDiscountNumeric') }}</div>
                <!-- <div class="errors" v-if="$v.order.discount.numeric && !$v.order.discount.minValue">{{ $t('orderDiscountMinValue') }}</div> -->
              </div>
            </b-col>
            <b-col sm="12" md="6" lg="3" :class="{ error: $v.order.delivery.$invalid }">
              <label>{{ $t('orderDelivery') }}</label>
              <b-form-input 
                v-model="$v.order.delivery.$model"
                @change="recalculateOrderSumamry"
                type="number" 
                :number="true" 
                required 
                min="0" 
              />
              <div v-if="$v.order.delivery.$invalid && $v.order.delivery.$dirty">
                <div class="errors" v-if="!$v.order.delivery.required">{{ $t('orderDeliveryRequired') }}</div>
                <!-- <div class="errors" v-if="$v.order.delivery.required && !$v.order.delivery.numeric">{{ $t('orderDeliveryNumeric') }}</div> -->
                <!-- <div class="errors" v-if="$v.order.delivery.numeric && !$v.order.delivery.minValue">{{ $t('orderDeliveryMinValue') }}</div> -->
              </div>
            </b-col>
            <b-col sm="12" md="6" lg="3" :class="{ error: $v.order.price.$invalid }">
              <label>{{ $t('orderTotalPrice') }}</label>
              <b-form-input v-model="$v.order.price.$model" type="number" :number="true" required min="0" />
              <div v-if="$v.order.price.$invalid && $v.order.price.$dirty">
                <div class="errors" v-if="!$v.order.price.required">{{ $t('orderPriceRequired') }}</div>
                <!-- <div class="errors" v-if="$v.order.price.required && !$v.order.price.numeric">{{ $t('orderPriceNumeric') }}</div> -->
                <!-- <div class="errors" v-if="$v.order.price.numeric && !$v.order.price.minValue">{{ $t('orderPriceMinValue') }}</div> -->
              </div>
            </b-col>
          </b-row>
          <b-row v-if="calculatedSummary.display" style="margin-top: 3px;">
            <b-col sm="12" md="6" lg="3">
              <span class="number-preview">{{ calculatedSummary.total }}</span>
            </b-col>
            <b-col sm="12" md="6" lg="3">
              <span class="number-preview">{{ calculatedSummary.discount }}</span>
            </b-col>
            <b-col sm="12" md="6" lg="3">
              <span class="number-preview">{{ calculatedSummary.delivery }}</span>
            </b-col>
            <b-col sm="12" md="6" lg="3">
              <span class="number-preview">{{ calculatedSummary.price }}</span>
              <b-button variant="primary" class="pull-right" @click="applySummary">{{ $t('apply') }}</b-button>
            </b-col>
          </b-row>
        </b-card>
        <h5>{{ $t('orderExtraInfo') }}</h5>
        <b-card>
          <b-row>
            <b-col sm="12" md="6" lg="3" :class="{ error: $v.order.statuses.$each[0].$invalid }">
              <label>{{ $t('status') }}</label>
              <b-form-select
                v-model="$v.order.statuses.$each[0].$model"
                :options="statusesList"
              />
            </b-col>
            <b-col sm="12" md="6" lg="3" :class="{ error: $v.order.managers.$each[0].$invalid }">
              <label>{{ $t('manager') }}</label>
              <b-form-select
                v-model="$v.order.managers.$each[0].$model"
                :options="managersList"
              />
            </b-col>
            <b-col sm="12" md="6" lg="3" :class="{ error: $v.order.drivers.$each[0].$invalid }">
              <label>{{ $t('driver') }}</label>
              <b-form-select
                v-model="$v.order.drivers.$each[0].$model"
                :options="driversList"
              />
            </b-col>
            <b-col sm="12" md="6" lg="3" :class="{ error: $v.order.paymentMethods.$each[0].$invalid }">
              <label>{{ $t('paymentMethod') }}</label>
              <b-form-select
                v-model="$v.order.paymentMethods.$each[0].$model"
                :options="paymentMethodsList"
              />
            </b-col>
          </b-row>
        </b-card>
        <b-row>
          <b-col sm="12" md="6" lg="3">
            <b-button
              type="submit"
              block
              variant="primary"
              :disabled="$v.$dirty && $v.$invalid"
              @click.prevent="submit"
            >{{ $t('save') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import datePicker from 'vue-bootstrap-datetimepicker';
  import i18n from "@/plugins/i18n";
  import {APIService} from "@/services/api";
  import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
  import OrderPositionAutocomplete from './components/OrderPositionAutocomplete';
  import {schema} from "./schema/order";

  export default {
    name: 'UpdateOrder',
    components: {datePicker, OrderPositionAutocomplete},
    props: ['id'],
    data: () => {
      return {
        order: null,
        statusesList: [],
        managersList: [],
        driversList: [],
        paymentMethodsList: [],
        dateOptions: {
          format: 'DD.MM.YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          locale: 'ru',
        },
        calculatedSummary: {
          display: false,
          total: 0,
          discount: 0,
          delivery: 0,
          price: 0,
        },
      }
    },
    validations: schema,
    methods: {
      getRole(order) {
        if (order.role === 'guest') {
          return 'Новый покупатель';
        }
        if (order.role === 'customer') {
          return 'Постоянынй покупатель';
        }
        if (order.role === 'farmer') {
          return 'Питомник';
        }
        return "&nbsp;&nbsp;";
      },
      getRoundValue(value) {
        return Math.round(value * 100) / 100;
      },
      addPosition() {
        this.$v.order.positions.$model.push({
          id: null,
          title: "",
          priceTagTitle: "",
          article: "",
          note: null,
          status: 1,
          quantity: 1,
          discount: 0,
          price: null,
          priceFixed: false,
          removed: false,
        })
      },
      removePosition(position) {
        position.removed.$model = true;
      },
      recalculateOrderSumamry() {
        if (!isNaN(this.order.total) && !isNaN(this.order.discount) && !isNaN(this.order.delivery)) {
          this.order.price = this.order.total - this.order.discount + this.order.delivery;
        }
      },
      applySummary() {
        this.order.total = this.calculatedSummary.total;
        this.order.discount = this.calculatedSummary.discount;
        this.order.delivery = this.calculatedSummary.delivery;
        this.order.price = this.calculatedSummary.price;
        this.calculatedSummary.display = false;
      },
      async submit() {
        this.$v.order.$touch();
        if (!this.$v.order.$invalid) {
          const data = Object.assign(this.order, this.$v.order.$model);
          data.deliveryDates[0].date = moment(data.deliveryDates[0].date, 'DD.MM.YYYY').toDate();
          data.drivers = data.drivers.filter(driver => driver.id);
          data.managers = data.managers.filter(manager => manager.id);
          data.paymentMethods = data.paymentMethods.filter(paymentMethod => paymentMethod.id);
          data.positions = data.positions.map(position => {
            if (position.product) {
              position.product = {
                id: position.product.id,
              };
            }
            return position;
          });
          try {
            const res = await APIService.patch(APIService.getRestFullEntityUrl('order', data), data);
            if (!res) {
              throw Error('Error on order save');
            }
            this.$eventBus.$emit('alert', {
              type: 'success',
              text: i18n.t('dataWasSuccessfullySaved'),
            });
            await this.$router.back();
          } catch (e) {
            console.error(e);
            this.$eventBus.$emit('alert', {
              type: 'danger',
              text: i18n.t('errorOnDataSaving'),
            });
          }
        }
      }
    },
    computed: {
      showAddPosition() {
        if (!this.order) {
          return false;
        }
        const activePositions = this.order.positions.filter(position => !position.removed);
        return activePositions.length<12;
      },
    },
    watch: {
      'order.positions': {
        handler: async function (newVal, oldVal) {
          if (oldVal) {
            const valid = newVal.every(p => p.price && p.quantity);
            if (valid) {
              const calculationSource = {
                summary: {
                  total: this.order.total,
                  discount: this.order.discount,
                  delivery: this.order.delivery,
                  price: this.order.price,
                },
                positions: newVal.filter(p => !p.removed),
                role: this.order.role,
              };
              const orderCaluldations = await APIService.post('calculate/order', calculationSource);
              const {summary} = orderCaluldations;
              if (
                summary.total !== this.calculatedSummary.total || 
                summary.discount !== this.calculatedSummary.discount ||
                summary.delivery !== this.calculatedSummary.delivery ||
                summary.price !== this.calculatedSummary.price
              ) {
                  this.calculatedSummary = {
                    display: true,
                    total: summary.total,
                    discount: summary.discount,
                    delivery: summary.delivery,
                    price: summary.price,
                  };
              }  
            }
          }
        },
        deep: true
      }
    },
    async beforeMount() {
      try {
        const order = await APIService.get(`order/${this.id}?join=forms&join=statuses&join=drivers&join=managers&join=paymentMethods&join=positions&join=positions.product&join=positions.priceTag&join=deliveryDates&join=users&join=users.alerts`);
        this.calculatedSummary = {
          display: false,
          total: order.total,
          discount: order.discount,
          delivery: order.delivery,
          price: order.price,
        };
        order.deliveryDates[0].date = moment(order.deliveryDates[0].date).format('DD.MM.YYYY');
        if (!order.drivers || order.drivers.length === 0) {
          order.drivers = [{
            id: null,
            title: '',
          }];
        }
        if (!order.managers || order.managers.length === 0) {
          order.managers = [{
            id: null,
            title: '',
          }];
        }
        if (!order.statuses || order.statuses.length === 0) {
          order.statuses = [{
            id: null,
            title: '',
          }];
        }
        if (!order.paymentMethods || order.paymentMethods.length === 0) {
          order.paymentMethods = [{
            id: null,
            title: '',
          }];
        }
        this.order = order;
        const driversList = await APIService.get(`driver`)
        this.driversList = driversList.map(driver => ({
          value: driver,
          text: driver.title,
        }));
        const managersList = await APIService.get(`manager`)
        this.managersList = managersList.map(manager => ({
          value: manager,
          text: manager.title,
        }));
        const statusesList = await APIService.get(`status`)
        this.statusesList = statusesList.map(status => ({
          value: status,
          text: status.title,
        }));
        const paymentMethodsList = await APIService.get(`payment-method`)
        this.paymentMethodsList = paymentMethodsList.map(paymentMethod => ({
          value: paymentMethod,
          text: paymentMethod.title,
        }));
      } catch (e) {
        console.error(e);
      }
    }
  }
</script>

<style lang="scss">
.vue-form-generator {
  .error {
    input {
      border-color: red;
    }
    select {
      border-color: red;
    }
    div {
      color: red;
    }
  }
  .number-preview{
    padding-left: 12px;
    color: #606266;
  }
  .fixed-price {
    border-color: #000;
  }
}
</style>
