<template>
    <b-row>
        <b-col sm="12" md="8" lg="8" :class="{ error: position.title.$dirty && position.title.$invalid }">
            <vue-autosuggest
                v-model="position.title.$model"
                :suggestions="[{data: products}]"
                :input-props="{class: 'form-control'}"
                component-attr-class-autosuggest-results-container="autocomplete-results"
                @input="onInputChange"
                @selected="selectProductHandler"
            >
                <template slot-scope="{suggestion}">
                    <div>
                        <b-row>
                            <b-col sm="3">
                                <img width="70" :src="getImageUrl(suggestion.item)">
                            </b-col>
                            <b-col sm="9">
                                <div>
                                    <b>{{ suggestion.item.tagItems[0].brandName }}</b>
                                </div>
                                <div>
                                    {{ suggestion.item.brandName }}
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </template>
            </vue-autosuggest>
        </b-col>
        <b-col sm="12" md="4" lg="4" :class="{ error: position.priceTagTitle.$dirty && position.priceTagTitle.$invalid }">
            <vue-autosuggest
                v-model="position.priceTagTitle.$model"
                :suggestions="[{data: tagItemPrices}]"
                :input-props="{class: 'form-control'}"
                component-attr-class-autosuggest-results-container="autocomplete-results"
                @selected="selectPriceTagHandler"
            >
                <template slot-scope="{suggestion}">
                    <span class="my-suggestion-item">{{suggestion.item.tagItem.value}}</span>
                </template>
            </vue-autosuggest>
        </b-col>
    </b-row>
</template>
  
<script>
import { VueAutosuggest } from 'vue-autosuggest';
import {APIService} from "@/services/api";

export default {
    components: {VueAutosuggest},
    props: ['position', 'role', 'index'],
    data: () => {
      return {
        products: [],
        tagItemPrices: [],
      }
    },
    methods: {
        async onInputChange(query) {
            try {
                this.products = await APIService.get(`product/autocomplete/${query}`);
            }
            catch(e) {
                console.error(e);
            }
        },
        selectProductHandler(selected) {
            const product = selected.item;
            this.position.title.$model = product.brandName;
            this.position.product.$model = product;
            this.tagItemPrices = product.tagPriceItems;
        },
        async selectPriceTagHandler(selected) {
            const priceTagItem = selected.item;
            this.position.priceTagTitle.$model = priceTagItem.tagItem.value;
            this.position.article.$model = priceTagItem.sku;
            this.position.price.$model = await this.getPositionPrice(this.position.product.$model, priceTagItem);
            this.position.priceTag.$model = priceTagItem;
        },
        async getPositionPrice(product, priceTag) {
            const position = this.position.$model;
            position.product = product;
            position.priceTag = priceTag;
            const calculatePosition = {
                position,
                role: this.role,
            };
            let price = null;
            try {
                const result = await APIService.post(`calculate/order-position`, calculatePosition);
                price = result.price;
            }   
            catch(e) {
                console.error(e);
            }
            return price;
        },
        getImageUrl (product) {
            const image = product.images[0];
            if (image) {
                const modification = image.modifications.find(modification => modification.suffix === 'small')
                if (modification) {
                    return modification.fileUrl;
                }
            }
            return '';
        }
    },
}
</script>

<style lang="scss">
    .autocomplete-results{
        .autosuggest__results{
            border: 1px solid #e4e5e6;
            border-radius: 5px;
        }
        ul {
            list-style: none;
            padding-left: 0px;
            margin-bottom: 1px;
            li {
                padding: 5px 10px;
            }
        }
    }
</style>
  